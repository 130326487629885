import rio from "../assets/images/ICONOS/1.png";
import lobo from "../assets/images/ICONOS/2.png";
import castillo from "../assets/images/ICONOS/3.png";
import barco from "../assets/images/ICONOS/4.png";
import parque from "../assets/images/ICONOS/5.png";
import caballo from "../assets/images/ICONOS/6.png";
import vtm from '../assets/images/ICONOS/vtm.png'
export const actividades = [
  {
    img: caballo,
    title: "Equusduri",
    url: "equusduri.com",
  },
  {
    img: barco,
    title: "Europarques",
    url: "europarques.com",
  },
  {
    img: lobo,
    title: "A Vista de Lobo",
    url: "avistadelobo.com",
  },
  {
    img: parque,
    title: "La Casa del Duero",
    url: "lacasadelduero.com",
  },
  {
    img: castillo,
    title: "Patrimonio Natural",
    url: "patrimonionatural.org",
  },
  {
    img: vtm,
    title: "Viriato Tenis de Mesa",
    url: "instagram.com/viriato_tm/",
  },
];

import zamora1 from "./images/ZAMORA/1.jpg";
import zamora2 from "./images/ZAMORA/2.jpg";
import zamora3 from "./images/ZAMORA/3.jpg";
import zamora4 from "./images/ZAMORA/4.jpg";
import zamora5 from "./images/ZAMORA/5.jpg";
import zamora6 from "./images/ZAMORA/6.jpg";
import zamora7 from "./images/ZAMORA/7.jpg";
import zamora8 from "./images/ZAMORA/8.jpg";
import zamora9 from "./images/ZAMORA/9.jpg";


export const zamora = [

  {
    url: zamora1,
  },
  {
    url: zamora2,
  },
  {
    url: zamora3,
  },
  {
    url: zamora4,
  },
  {
    url: zamora5,
  },
  {
    url: zamora6,
  },
  {
    url: zamora7,
  },
  {
    url: zamora8,
  },
  {
    url: zamora9,
  },
];

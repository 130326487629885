import alfareria from "../assets/images/PAGINAS/alfareria.jpg";
import carakoles from "../assets/images/PAGINAS/caracoles.jpg";
import delokos from "../assets/images/PAGINAS/delokos.jpg";
import hato from "../assets/images/PAGINAS/hato.jpg";
import fruto from "../assets/images/PAGINAS/fruto.jpg";
import jose from "../assets/images/PAGINAS/jose.jpg";
import faya from "../assets/images/PAGINAS/faya.jpg";
import setera from "../assets/images/PAGINAS/setera.jpg";
import molinos from "../assets/images/PAGINAS/molinos.jpg";

export const gastronomy = [
  {
    img: alfareria,
    titulo: "Alfareria Pereruela",
    url: "pereruela.net",
  },
  {
    img: carakoles,
    titulo: "Caracoles de Sayago",
    url: "caracolesdesayago.es",
  },
  {
    img: delokos,
    titulo: "Delokos",
    url: "delokos.org",
  },
  {
    img: hato,
    titulo: "El Hato y El Garabato",
    url: "elhatoyelgarabato.com",
  },
  {
    img: fruto,
    titulo: "Fruto del Huerto",
    url: "frutodelhuerto.es",
  },
  {
    img: jose,
    titulo: "Jose Luis Redondo",
    url: "hornosdepereruela.com",
  },
  {
    img: faya,
    titulo: "La Faya",
    url: "lafaya.es",
  },
  {
    img: setera,
    titulo: "La Setera",
    url: "lasetera.com",
  },
  {
    img: molinos,
    titulo: "Molinos del duero",
    url: "molinosdelduero.com",
  },
];

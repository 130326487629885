import arribes1 from "./images/ARRIBES_DUERO/1.jpg";
import arribes2 from "./images/ARRIBES_DUERO/2.jpg";
import arribes3 from "./images/ARRIBES_DUERO/3.jpg";
import arribes4 from "./images/ARRIBES_DUERO/4.jpg";
import arribes5 from "./images/ARRIBES_DUERO/5.jpg";
import arribes6 from "./images/ARRIBES_DUERO/6.jpg";

export const arribes = [

  {
    url: arribes1,
  },
  {
    url: arribes2,
  },
  {
    url: arribes3,
  },
  {
    url: arribes4,
  },
  {
    url: arribes5,
  },
  {
    url: arribes6,
  },
];

import * as React from "react";
import Footer from "../components/Footer/footer";
import { Head } from "../components/Header/head";
import ModalCarousel from "../components/ModalCarousel/ModalCarousel";
import { Navbar } from "../components/Navbar/navbar";
import "../styles/experience.css";
import { arribes } from "../assets/arribes";
import { gastronomy } from "../assets/gastronomy";
import { actividades } from "../assets/actividades";
import { zamora } from "../assets/zamora";
import { SSRProvider } from "@react-aria/ssr";

const ExperiencesPage = () => {
  return (
    <SSRProvider>

      <Head title="Posada Los Jerónimos - Experiencias" />
      <Navbar transparent />

      <section class="environment" id="environment">
        <div class="title">
          <h1>Entorno</h1>
        </div>
        <div id="cards" class="cards">
          <div class="card-wrapper">
            <div class="card">
              <h2 class="card_title">Arribes del Duero</h2>
              <p>
                En el oeste de Zamora y Salamanca, donde el Duero se hace
                frontera con Portugal y se encajona formando los cañones más
                profundos y extensos -casi un centenar de kilómetros- de toda la
                Península Ibérica, se encuentra la comarca de Arribes del Duero.
                Un espacio natural privilegiado en el que destacan la belleza
                agreste de su paisaje Granítico y una rica y variada fauna y
                flora.
              </p>
            </div>
            <div class="card">
              <ModalCarousel images={arribes} portada={arribes[0].url} />
            </div>
            <div class="card">
              <ModalCarousel images={zamora} portada={zamora[0].url} />
            </div>
            <div class="card">
              <h2 class="card_title">Zamora</h2>
              <p>
                Una ciudad hermosa, tranquila y muy paseable. Puede presumir de
                ser una pequeña ciudad pero grande en historia, cultura y oferta
                patrimonial. Un destino que posee muy buenos recursos
                patrimoniales. El mejor románico urbano de la península con más
                de una veintena de edificaciones de este tipo. El Duero, el gran
                río de Castilla y de Portugal presidiendo la ciudad e icono de
                referencia turística de Zamora.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="gastronomy" class="gastronomy">
        <h1>Gastronomía y artesanía</h1>
        <div class="cards-gastronomy">
          {gastronomy.map((card) => {
            return (
              <div class="card-wrapper-gastronomy">
                <div class="card-gastronomy">
                  <a href={"https://" + card.url} target="_blank">
                    <img src={card.img} alt="" />
                  </a>
                </div>
                <h2 class="subtitle">{card.titulo}</h2>
              </div>
            );
          })}
        </div>
      </section>

      <section id="actividades" class="actividades">
        <h1>Actividades</h1>
        <div class="actividades-container">
          {actividades.map((card) => {
            return (
              <div class="actividades-card">
                <div class="icon">
                  <a href={"https://" + card.url} target="_blank">
                    <img class="icon-asset" src={card.img} alt="" />
                  </a>
                </div>
                <div class="descripcion">
                  <div class="actividades-card-titulo">{card.title}</div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <Footer slim />
    </SSRProvider>
  );
};

export default ExperiencesPage;
